<script lang="ts">
	import { fixTitleCharacters } from '$lib/utils/title-case'
	import { override } from '$lib/utils/classnames'
	import { twMerge } from 'tailwind-merge'

	interface Props {
		class?: string
		title: string
		tripTitle?: string | null
	}

	let { class: className = '', title, tripTitle = null }: Props = $props()

	let fixedTitle = $derived(fixTitleCharacters(title))

	let computedClassName = $derived(
		twMerge(
			override(
				'font-normal text-sm lg:text-xl tracking-tighter truncate p-0 bg-transparent w-full text-black dark:text-white -mb-1 lg:-mb-0',
				className,
			),
		),
	)
</script>

<div class={computedClassName}>
	<p class="text-xs text-brand-gray-4 mt-2 lg:mt-3 -mb-1 h-4">
		{tripTitle ?? ''}
	</p>
	{@html fixedTitle != null && fixedTitle != '' ? fixedTitle : 'No Title'}
</div>
